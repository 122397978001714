import { Game, Tag } from "..";
import { _3DM } from "../PreviewComponents/3dm";
import { FontPreview } from "../PreviewComponents/font";
import { ImagePreview } from "../PreviewComponents/image";
import { SoundPreview } from "../PreviewComponents/sound";
import { VideoPreview } from "../PreviewComponents/video";

type TagSelectionProps = {
    tags: Array<string>,
    selectedGame: Game,
    selectTag: Function,
    setSelectedGame: Function
}

const TAG_MAP: {[key: string]: Tag} = {
    "3dm": {
        id: "3dm",
        name: "3D Models",
        preview: _3DM
    },
    "bgm": {
        id: "bgm",
        name: "Music",
        preview: SoundPreview
    },
    "sfx": {
        id: "sfx",
        name: "Sound Effects",
        preview: SoundPreview
    },
    "tex": {
        id: "tex",
        name: "Textures",
        preview: ImagePreview
    },
    "mov": {
        id: "mov",
        name: "Pre-rendered Scenes",
        preview: VideoPreview
    },
    "fnt": {
        id: "fnt",
        name: "Fonts",
        preview: FontPreview
    }
}

export function TagSelection(props: TagSelectionProps) {
    const tagElements = [];
    for(let tag of props.tags) {
        const tagEntry = TAG_MAP[tag];
        if(!tagEntry)
            continue;
        tagElements.push(<p key={tagEntry.id} className="Button" onClick={() => props.selectTag(tagEntry)}>{tagEntry.name}</p>);
    }

    return <>
        <h1>{props.selectedGame.name}</h1>
        {tagElements}
        <p className="Button" onClick={() => props.setSelectedGame(null)}>Back</p>
    </>
}