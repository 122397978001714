import React, { useState } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './common.css';
import { PageLogin } from './pages/login';
import { GameAssets } from './pages/gameassets';

const NAV_MAP: {[key: string]: JSX.Element} = {
	gameassets: <GameAssets/>
}

function Root() {
	const [site, setSite] = useState<string | null>(null);

	if(site === null)
		return <PageLogin setSite={setSite}/>

	return NAV_MAP[site];
}

const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement
);
root.render(
	<React.StrictMode>
		<Root />
	</React.StrictMode>
);
