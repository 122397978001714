import { useEffect, useState } from "react"
import { API } from "../../../../api/api";
import Spinner from "../../../../components/Spinner";
import 'react-h5-audio-player/lib/styles.css';

type FontProps = {
    game: string,
    tag: string,
    id: string
}

export function FontPreview(props: FontProps) {
    const [fontUrl, setFontUrl] = useState<string | null>(null);

    useEffect(() => {
        (async () => {
            setFontUrl(null);
            const files: Array<string> = await (await API.send(`gameassets/files?game=${props.game}&tag=${props.tag}&asset=${encodeURIComponent(props.id)}`, "GET"))?.json();
            setFontUrl(`${API.apiURL}/gameassets/file?game=${props.game}&tag=${props.tag}&asset=${encodeURIComponent(props.id)}&file=${encodeURIComponent(files[0])}`)
        })()
    }, [props]);

    if(!fontUrl)
        return <Spinner active/>

    return (
        <div className="FontPreviewContainer">
            <style>
                {`
                    @font-face {
                        font-family: "CustomFont";
                        src: url(${fontUrl}) format("truetype");
                    }
                `}
            </style>
            <p style={{ fontFamily: "CustomFont" }}>The quick brown fox jumps over the lazy dog.</p>
            <p style={{ fontFamily: "CustomFont" }}>0123456789</p>
            <p style={{ fontFamily: "CustomFont" }}>.,;:'"!?()[]{}+=-*/^%$€£¥₹@#&*/|\~_-&lt;&gt;!?^°</p>
        </div>
    );
}