import { ColladaLoader } from 'three/examples/jsm/loaders/ColladaLoader'
import { API } from "../../../../api/api";
import { useCallback, useEffect, useState } from "react";
import { CanvasTexture, LoadingManager, Mesh, Object3D, Scene, TextureLoader } from "three";
import { ActiveMaterialMap, GenericLoader, MaterialMap, ModelConfig, ObjectToggleMap, _3DMProps } from "./3dm.types";
import { ToggleableSelector } from "./ToggleableSelector";
import { ModelDisplay } from "./ModelDisplay";
import Spinner from "../../../../components/Spinner";
import './3DM.css'
import { setKeyInStateObject } from '../../../../util/stateUtil';

const LOADER_MAP: {[key: string]: GenericLoader} = {
    "dae": ColladaLoader
}

export function _3DM(props: _3DMProps) {
    const [object, setObject] = useState<Object3D | null>(null);
    const [currentAsset, setCurrentAsset] = useState<string | null>(null);
    const [materialMap, setMaterialMap] = useState<MaterialMap | null>(null);
    const [activeMatMap, setActiveMatMap] = useState<ActiveMaterialMap | null>(null);
    const [objToggleMap, setObjToggleMap] = useState<ObjectToggleMap>({});

    const [modelFiles, setModelFiles] = useState<string[]>([]);
    const [selectedModel, setSelectedModel] = useState<number>(0);
    const [modelConfig, setModelConfig] = useState<ModelConfig>({});

    const loadFiles = useCallback(async () => {
        setObject(null);
        setModelFiles([]);
        setSelectedModel(0);
        setObjToggleMap({});
        setModelConfig({})
        const files: Array<string> = (await (await API.send(`gameassets/files?game=${props.game}&tag=${props.tag}&asset=${encodeURIComponent(props.id)}`, "GET"))?.json()).sort();
        let curMatMap: ActiveMaterialMap | null = activeMatMap;
        
        let matMap: MaterialMap = {};
        if(files.includes("__matMap.json") && !materialMap) {
            matMap = await (await API.send(`gameassets/file?game=${props.game}&tag=${props.tag}&asset=${encodeURIComponent(props.id)}&file=__matMap.json`, "GET"))?.json();
            setMaterialMap(matMap);
            curMatMap = {};
            for(let mat of Object.keys(matMap).sort()) {
                curMatMap[mat] = matMap[mat][Object.keys(matMap[mat]).sort()[0]]
            }
            setActiveMatMap(curMatMap);
        }

        if(files.includes("__modelConfig.json")) {
            const newModelConfig: ModelConfig = await (await API.send(`gameassets/file?game=${props.game}&tag=${props.tag}&asset=${encodeURIComponent(props.id)}&file=__modelConfig.json`, "GET"))?.json();
            setModelConfig(newModelConfig)
            if(newModelConfig.toggleableObjects) {
                const newObjToggleMap: ObjectToggleMap = {};
                for(const [toggleName, toggle] of Object.entries(newModelConfig.toggleableObjects))
                    newObjToggleMap[toggleName] = toggle.default
                setObjToggleMap(newObjToggleMap);
            }
        }

        const newModelFiles = [];
        for(let file of files) {
            for(let type in LOADER_MAP) {
                if(file.includes(`.${type}`)) {
                    newModelFiles.push(file);
                    break;
                }
            }
        }
        setModelFiles(newModelFiles);
    }, [props.game, props.tag, props.id, activeMatMap, materialMap]);

    const processModelConfig = useCallback(async (model: Scene, baseurl: string) => {
        // Hack for non-explicitly declared alpha maps, manually iterates over all meshes and loads corresponding alpha maps if configured
        if(modelConfig.alphaMaps) {
            const alphaTexLoader = new TextureLoader();
            alphaTexLoader.setPath(baseurl);
            for(let objName in modelConfig.alphaMaps) {
                for(let obj of model.children) {
                    if(obj instanceof Mesh && obj.name === objName) {
                        let alphaMap = await alphaTexLoader.loadAsync(modelConfig.alphaMaps[objName]);
                        obj.material.alphaMap = alphaMap;
                        obj.material.transparent = true;
                        obj.material.toneMapped = false;
                        //obj.material.alphaTest = 0.5;
                        break;
                    }
                }
            }
        }

        // Hack for models where the color map has an alpha channel that shouldn't be used
        if(modelConfig.removeColorMapAlpha) {
            for(let obj of model.children) {
                if(obj instanceof Mesh) {
                    const img = obj.material.map.image;
                    const dummyCanvas = document.createElement("canvas");
                    dummyCanvas.width = img.width;
                    dummyCanvas.height = img.height;
                    const ctx = dummyCanvas.getContext("2d")
                    ctx?.drawImage(img, 0, 0, img.width, img.height);

                    const imgData = ctx?.getImageData(0, 0, dummyCanvas.width, dummyCanvas.height) as ImageData
                    for(let i = 3; i < imgData.data.length; i += 4)
                        imgData.data[i] = 255;
                    ctx?.putImageData(imgData, 0, 0);

                    obj.material.map.dispose()
                    obj.material.map = new CanvasTexture(dummyCanvas);
                }
            }
        }
    }, [modelConfig]);

    const loadModel = useCallback(async () => {
        let loaderClass: GenericLoader | null = null;
        let modelFile = modelFiles[selectedModel];
        if(!modelFile)
            return;

        for(let type in LOADER_MAP) {
            if(modelFile.includes(`.${type}`)) {
                loaderClass = LOADER_MAP[type];
                break;
            }
        }

        if(loaderClass === null)
            return;

        const manager = new LoadingManager();
        const loader = new loaderClass(manager);
        const matConfig = activeMatMap === null ? null : Object.values(activeMatMap);
        const modelUrl = matConfig === null ?
            `${API.apiURL}/gameassets/file?game=${props.game}&tag=${props.tag}&asset=${encodeURIComponent(props.id)}&file=` :
            `${API.apiURL}/gameassets/file?game=${props.game}&tag=${props.tag}&asset=${encodeURIComponent(props.id)}&matConfig=${matConfig}&file=`;
        loader.setPath(modelUrl)

        let model: Scene;
        manager.onLoad = () => {
            processModelConfig(model, modelUrl)
        }

        model = (await loader.loadAsync(modelFile)).scene;
        setObject(model);
        setCurrentAsset(props.id);
    }, [modelFiles, selectedModel, activeMatMap, props.game, props.tag, props.id, processModelConfig]);

    function switchModel(offset: number) {
        let newSelectedModel = selectedModel + offset;
        newSelectedModel = Math.max(0, newSelectedModel);
        newSelectedModel = Math.min(modelFiles.length - 1, newSelectedModel)
        setSelectedModel(newSelectedModel);
    }

    useEffect(() => {
        if(currentAsset === props.id)
            return;
        loadFiles();
    }, [props, currentAsset, loadFiles]);

    useEffect(() => { loadModel() }, [activeMatMap, modelFiles, selectedModel, loadModel])

    if(object) {
        const matSelectElem = [];
        if(materialMap && activeMatMap) {
            for(let mat of Object.keys(materialMap).sort()) {
                const options = []
                for(let opt of Object.keys(materialMap[mat]).sort()) {
                    options.push(
                        <option className="matSelectOption" value={materialMap[mat][opt]}>{opt}</option>
                    )
                }
    
                matSelectElem.push(
                    <div className="matSelectRow">
                        <p className="matSelectTitle">{mat}</p>
                        <select className="matSelectDropdown" value={activeMatMap[mat]} onChange={(e) => setKeyInStateObject(activeMatMap, mat, e.target.value, setActiveMatMap)}>
                            {options}
                        </select>
                    </div>
                )
            }
        }

        let modelSelectorElem = null;
        if(modelFiles.length > 1) {
            modelSelectorElem = <>
                <p className="ModelSelectorButton" onClick={() => {switchModel(-1)}}>&lt;</p>
                <p>Model {selectedModel+1} of {modelFiles.length}</p>
                <p className="ModelSelectorButton" onClick={() => {switchModel(+1)}}>&gt;</p>
            </>
        }

        return <div className="_3dmPreviewContainer">
            <ModelDisplay object={object} config={modelConfig} objToggleMap={objToggleMap}/>
            <div className="matSelectContainer">
                {matSelectElem}
            </div>
            <div className="modelSelectorContainer">
                {modelSelectorElem}
            </div>
            <ToggleableSelector onToggle={(toggle) => setKeyInStateObject(objToggleMap, toggle, !objToggleMap[toggle], setObjToggleMap)} toggles={objToggleMap}/>
        </div>
    }

    return <Spinner active/>
}
