export class API {
    static apiURL: string = process.env.REACT_APP_API_URL || "https://citadel.ix.tc:6969/treasureplanet";
    static apiToken: string | null = null;

    static async getToken(spatial: string, temporal: string): Promise<Response | null> {
        try {
            const options = {
                method: "POST",
                body: JSON.stringify({
                    spatial, temporal
                }),
                headers: {
                    "Content-Type": "application/json"
                }
            };
            return await fetch(`${API.apiURL}/navigate`, options);
        } catch(e) {
            return null;
        }
    }

    static async send(url: string, method: string, body?: string): Promise<Response | null> {
        try {
            const options: RequestInit = {
                method: method,
                headers: {
                    "Authorization": `Bearer ${API.apiToken}`
                }
            };
            if(body)
                options.body = JSON.stringify(body);
            return await fetch(`${API.apiURL}/${url}`, options);
        } catch(e) {
            return null;
        }
    }
}