import { KeyboardEvent, useCallback, useEffect, useState } from "react";
import { PreviewWrapper } from "../PreviewComponents/wrapper";
import './AssetList.css';
import { API } from "../../../api/api";

type AssetsListProps = {
    selectedTag: any,
    selectedGame: any,
    setSelectedTag: Function
}

export function AssetList(props: AssetsListProps) {
    const [assets, setAssets] = useState<Array<string>>([]);
    const [page, setPage] = useState(1);
    const [maxPage, setMaxPage] = useState(1);
    const [search, setSearch] = useState("");

    const loadAssets = useCallback(async () => {
        const assetsRes = await API.send(`gameassets/assets?game=${props.selectedGame?.id}&tag=${props.selectedTag.id}&search=${search}`, "GET");
        const assetsData = await assetsRes?.json();
        setAssets(assetsData.assets);
        setMaxPage(assetsData.pages);
        setPage(1);
    }, [props.selectedGame, props.selectedTag]); // eslint-disable-line react-hooks/exhaustive-deps
    useEffect(() => {loadAssets()}, [loadAssets]);

    async function goToPage(newPage: number) {
        newPage = Math.min(maxPage, Math.max(1, newPage))
        const assetsRes = await API.send(`gameassets/assets?game=${props.selectedGame?.id}&tag=${props.selectedTag?.id}&page=${newPage}&search=${search}`, "GET");
        const assetsData = await assetsRes?.json();
        setAssets(assetsData.assets);
        setMaxPage(assetsData.pages);
        setPage(newPage);
    }

    async function gotoPageInput() {
        const input = prompt("Enter page number to go to:");
        if(!input)
            return;
        const goto = parseInt(input);
        if(isNaN(goto) || !isFinite(goto) || goto < 0 || goto > maxPage)
            return;
        
        const assetsRes = await API.send(`gameassets/assets?game=${props.selectedGame?.id}&tag=${props.selectedTag?.id}&page=${goto}&search=${search}`, "GET");
        const assetsData = await assetsRes?.json();
        setAssets(assetsData.assets);
        setMaxPage(assetsData.pages);
        setPage(goto);
    }

    async function doSearch(e: KeyboardEvent<HTMLInputElement>) {
        if(e.key !== "Enter")
            return;

        setPage(1);
        const assetsRes = await API.send(`gameassets/assets?game=${props.selectedGame?.id}&tag=${props.selectedTag?.id}&search=${search}`, "GET");
        const assetsData = await assetsRes?.json();
        setAssets(assetsData.assets);
        setMaxPage(assetsData.pages);
    }

    const assetElements = [];
    for(let asset of assets) {
        assetElements.push(
            <PreviewWrapper
                key={asset}
                component={props.selectedTag.preview}
                game={props.selectedGame.id}
                tag={props.selectedTag.id}
                id={asset} 
                index={assetElements.length}
            />
        );
    }

    return <>
        <h1>{props.selectedGame.name} - {props.selectedTag.name}</h1>
        <input className="AssetSearch" placeholder="Search..." value={search} onChange={(e) => setSearch(e.target.value)} onKeyDown={doSearch}/>
        <div className="AssetList">
            {assetElements}
        </div>
        <div className="NavContainer">
            <p className="Button" onClick={() => goToPage(1)}>&lt;&lt;</p>
            <p className="Button" onClick={() => goToPage(page-1)}>&lt;</p>
            <p onDoubleClick={() => gotoPageInput()} style={{userSelect: "none"}}>Page {page} of {maxPage}</p>
            <p className="Button" onClick={() => goToPage(page+1)}>&gt;</p>
            <p className="Button" onClick={() => goToPage(maxPage)}>&gt;&gt;</p>
        </div>
        <p className="Button" onClick={() => props.setSelectedTag(null)}>Back</p>
    </>
}