import { KeyboardEvent, useState } from "react"
import { StarsBackdrop } from "../../components/StarsBackdrop/StarsBackdrop"
import "./PageLogin.css"
import Spinner from "../../components/Spinner";
import { API } from "../../api/api";
import { NavigationData } from "../../api/api.types";

type LoginProps = {
    setSite: Function
};

export function PageLogin(props: LoginProps) {
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [spatial, setSpatial] = useState("");
    const [temporal, setTemporal] = useState("");

    const onKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
        if(e.key === "Enter")
            onLoginPress();
    }

    const onLoginPress = async () => {
        if(loading)
            return;

        setError(false);
        setLoading(true);

        const tokenRes = await API.getToken(spatial, temporal);
        if(tokenRes === null || tokenRes.status !== 200) {
            setError(true);
            setLoading(false);
            return;
        }
        const navData: NavigationData = (await tokenRes.json()) as NavigationData;
        API.apiToken = navData.token;
        props.setSite(navData.destination);
    }

    return <>
        <StarsBackdrop/>
        <div className="Login-Container">
            <div className="Typewriter">
                <h1>Stowed with riches beyond imagination.</h1>
            </div>
            <div className="Login-Field-Container">
                <p className="Login-Field-Text">Enter intergalactic spacetime coordinates.</p>
                <label className="Login-Field-Label">Spatial Identifier:</label>
                <input className="Login-Field" type="username" value={spatial} onChange={(e) => setSpatial(e.target.value)} onKeyDown={onKeyDown}/>
                <label className="Login-Field-Label">Temporal Identifier:</label>
                <input className="Login-Field" type="password" value={temporal} onChange={(e) => setTemporal(e.target.value)} onKeyDown={onKeyDown}/>
                <button onClick={onLoginPress} className="Login-Button">Travel</button>
            </div>

            {error ? (
                <div className="Typewriter">
                    <p style={{color: "red"}}>You find nothing but emptiness.</p>
                </div>
            ) : (
                <div style={{height: "1.75vw"}}/>
            )}

            <Spinner active={loading}/>
        </div>
    </>
}