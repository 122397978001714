import { Mesh, Object3D } from "three";
import { ModelConfig, ModelDisplayProps, ObjectToggleMap } from "./3dm.types";
import { Canvas } from "@react-three/fiber";
import { useCallback, useEffect } from "react";
import { Bounds, OrbitControls, PerspectiveCamera, Stage } from "@react-three/drei";

export function ModelDisplay(props: ModelDisplayProps) {

    const updateObjectVisibility = useCallback((object: Object3D, config: ModelConfig, toggleMap: ObjectToggleMap) => {
        if(!config.toggleableObjects)
            return;

        if(object instanceof Mesh) {
            const objName = object.name;
            for(let [toggleName, toggle] of Object.entries(config.toggleableObjects)) {
                if(toggle.objects.includes(objName))
                    object.visible = toggleMap[toggleName];
            }
        }

        if(object.children && object.children.length > 0) {
            for(const child of object.children)
                updateObjectVisibility(child, config, toggleMap);
        }
    }, []);

    useEffect(() => {
        if(props.config.toggleableObjects)
            updateObjectVisibility(props.object, props.config, props.objToggleMap)
    }, [props, updateObjectVisibility])

    return <Canvas>
        <PerspectiveCamera/>
        <OrbitControls makeDefault/>
        <Stage shadows="contact" preset="soft">
            <Bounds fit clip observe>
                <mesh>
                    <primitive object={props.object}/>
                    <ambientLight color="white" intensity={3}/>
                    <directionalLight position={[1,1,-1]} intensity={3}/>
                    <directionalLight position={[1,1,1]} intensity={1}/>
                </mesh>
            </Bounds>
        </Stage>
    </Canvas>
}