import { ToggleableSelectorProps } from "./3dm.types";

export function ToggleableSelector(props: ToggleableSelectorProps) {
    const toggleElems = [];
    for(const toggle of Object.keys(props.toggles)) {
        toggleElems.push(
            <div
                className={`ToggleableSelection ${props.toggles[toggle] ? "Active" : "Inactive"}`}
                onClick={() => props.onToggle(toggle)}
            >{props.toggles[toggle] ? "👁" : "⨯"} {toggle}</div>
        )
    }

    if(Object.keys(props.toggles).length === 0)
        return null;

    return <div className="ToggleableSelectorContainer">
        <p><b>Toggles:</b></p>
        {toggleElems}
    </div>
}