import { useEffect, useState } from "react"
import { API } from "../../api/api";
import "./GameAssets.css"
import { AssetList } from "./AssetList";
import { TagSelection } from "./TagSelection";
import { GameSelection } from "./GameSelection";
import Spinner from "../../components/Spinner";

export type Game = {
    id: string,
    name: string
};

export type Tag = {
    id: string,
    name: string,
    preview: Function
};

export function GameAssets() {
    const [games, setGames] = useState<Array<Game>>([]);
    const [selectedGame, setSelectedGame] = useState<Game | null>(null);
    const [tags, setTags] = useState<Array<string>>([]);
    const [selectedTag, setSelectedTag] = useState<Tag | null>(null);

    async function loadGames() {
        setGames([]);
        const gamesRes = await API.send("gameassets/games", "GET");
        setGames((await gamesRes?.json()).sort());
    }
    useEffect(() => {loadGames()}, []);

    async function selectGame(game: Game) {
        setSelectedGame(game);

        setTags([]);
        const tagsRes = await API.send(`gameassets/tags?game=${game.id}`, "GET");
        setTags((await tagsRes?.json()).sort());
    }

    if(!selectedGame)
        return games.length > 0 ? <GameSelection games={games} selectGame={selectGame}/> : <Spinner active/>;
    else if(!selectedTag)
        return tags.length > 0 ? <TagSelection tags={tags} selectedGame={selectedGame} selectTag={setSelectedTag} setSelectedGame={setSelectedGame}/> : <Spinner active/>;
    else
        return <AssetList selectedTag={selectedTag} selectedGame={selectedGame} setSelectedTag={setSelectedTag}/>;
}