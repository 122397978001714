import { useEffect, useState } from "react"
import { API } from "../../../../api/api";
import Spinner from "../../../../components/Spinner";
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import './SoundPreview.css';

type SoundProps = {
    game: string,
    tag: string,
    id: string
}

export function SoundPreview(props: SoundProps) {
    const [filenames, setFilenames] = useState<string[]>([]);
    const [urls, setUrls] = useState<string[]>([]);
    const [version, setVersion] = useState(0);

    useEffect(() => {
        (async () => {
            setUrls([]);
            setVersion(0);
            const files: Array<string> = await (await API.send(`gameassets/files?game=${props.game}&tag=${props.tag}&asset=${encodeURIComponent(props.id)}`, "GET"))?.json();
            setUrls(files.map(f => `${API.apiURL}/gameassets/file?game=${props.game}&tag=${props.tag}&asset=${encodeURIComponent(props.id)}&file=${encodeURIComponent(f)}`));
            setFilenames(files.map(f => f.substring(0, f.lastIndexOf("."))))
        })()
    }, [props]);

    function navigateVersion(offset: number) {
        const newVersion = Math.max(0, Math.min(urls.length - 1, version + offset));
        setVersion(newVersion);
    }

    if(!urls[version] || !filenames[version])
        return <Spinner active/>

    return <div className="SoundPreviewContainer">
        <AudioPlayer
            footer={`${filenames[version]} (Version ${version+1} of ${filenames.length})`}
            src={urls[version]}
            onClickNext={() => navigateVersion(+1)}
            onClickPrevious={() => navigateVersion(-1)}
            showSkipControls
            showJumpControls={false}
        />
    </div>
}