import { useEffect, useState } from 'react';
import './PreviewWrapper.css';
import { API } from '../../../../api/api';

type PreviewWrapperProps = {
    component: any,
    game: string,
    tag: string,
    id: string,
    index: number
};

const ANIMATION_DELAY_FACTOR = 0.1;

export function PreviewWrapper(props: PreviewWrapperProps) {
    const [displayElem, setDisplayElem] = useState(<props.component id={props.id} game={props.game} tag={props.tag}/>);

    async function downloadAsset() {
        window.open(`${API.apiURL}/gameassets/download?game=${props.game}&tag=${props.tag}&asset=${props.id}`, "_self")
    }

    useEffect(() => {
        setDisplayElem(<props.component id={props.id} game={props.game} tag={props.tag}/>);
    }, [props]);

    return <div className='PreviewWrapper' style={{animationDelay: `${ANIMATION_DELAY_FACTOR * props.index}s`}}>
        <div className='PreviewDisplay'>
            {displayElem}
        </div>
        <div className='NameDisplay' onClick={downloadAsset}>
            <p>{props.id}</p>
        </div>
    </div>
}