import { Game } from "..";

type GameSelectionProps = {
    games: Array<Game>,
    selectGame: Function
}

export function GameSelection(props: GameSelectionProps) {
    const gameElements = [];
    for(let game of props.games) {
        gameElements.push(<p key={game.id} className="Button" onClick={() => props.selectGame(game)}>{game.name}</p>);
    }

    return <>
        <h1>Game Assets</h1>
        {gameElements}
    </>
}